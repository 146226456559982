export const items = [
  {
    title: "Dashbord",
     icon: "mdi-home",
    to: "/dashboard",
   },
   {
    title: "Admin & finance",
     icon: "mdi-microsoft-xbox-controller-menu",
     group: "administration-finance",
     subheader: "Administration & Financier",
     //divider: 'WORKESPACE',
     children: [
       {
         title: "Profil Organisation",
         icon: "mdi-folder-account-outline",
         to: "profil-organisation"
       },
       {
        title: "Administrations Projets",
        icon: "mdi-cast-variant",
        to: "administrations-projets"
      },
      {
        title: "Finances projets",
        icon: "mdi-finance",
        to: "finances-projets"
      },
      
 
     ]
   },
   {
     
     subheader: "Domaine intervention &  AGR",
  },
  {
    title: "Domaine intervention",
     icon: "mdi-folder-text-outline",
     to: "domaine-intervention"
    
  },
  {
     title: "AGR",
     icon: "mdi-folder-table",
    to: "/agr" 
    
  },
  {
   subheader: "Parametres",
},
  {
    title: "Comptes",
    icon: "mdi-shield-lock",
    group: "comptes",
    children: [
      {
        title: "Administrateurs",
        icon: "mdi-account-lock",
        to: "learning-management"
      },
      {
        title: "Animateurs",
        icon: "mdi-account-lock-outline",
        to: "learning-management"
      },
    ]
   
 },




   

];
